<ng-container
  *rxLet="
    (!!note && upsertNote.observed) ||
      (!!customPrice && upsertPrice.observed) ||
      quantityChange.observed ||
      (cigar.ProductId && smokingTime && upsertSmokingTime.observed) ||
      (myRating !== null && upsertRating.observed);
    let hasButtons
  "
>
  <div class="flex flex-col justify-center h-full">
    <div
      *ngIf="date"
      class="absolute top-0 left-0 z-10 inline-flex items-center px-2 py-1 text-[11px] font-medium text-gray-600 dark:text-gray-200 bg-gray-50 dark:bg-gray-900"
    >
      {{ date | date : 'MMM d, yy' }}
    </div>
    <div
      class="flex px-2"
      [ngClass]="{ 'mt-[20px]': date }"
      (click)="cigarClicked.observed && clickItem($event)"
    >
      <div class="self-center flex-shrink-0 mr-2">
        <cigar-brand-logo
          *ngIf="displayLogo"
          [cigar]="cigar"
        ></cigar-brand-logo>
      </div>
      <div
        class="space-y-[2px] flex-1 flex flex-col justify-center overflow-hidden"
      >
        <ng-container *rxLet="length | cigarSize : ringGauge; let size">
          <h4
            class="!m-0 text-sm font-bold uppercase leading-4"
            [ngClass]="{ '!line-clamp-2': truncateTitle }"
          >
            {{ name }} <span *ngIf="size">{{ size }}</span>
          </h4>
        </ng-container>
        <ng-content select="[belowTitle]"></ng-content>
        <div
          class="flex gap-1 truncate subtitle break-keep"
          *ngIf="
            prices?.SinglePriceMin ||
            prices?.SinglePrice ||
            prices?.BoxPriceMin ||
            prices?.BoxPriceMax
          "
        >
          <span
            class="truncate"
            *ngIf="prices?.SinglePriceMin || prices?.SinglePrice"
          >
            <b>Sgl:</b>
            {{
              prices?.SinglePrice
                ? (prices?.SinglePrice | currency)
                : ([prices?.SinglePriceMin, prices?.SinglePriceMax]
                  | priceRange)
            }}
          </span>
          <span
            class="truncate"
            *ngIf="prices?.BoxPriceMin || prices?.BoxPriceMax"
          >
            <b>Box:</b>
            {{
              [prices?.BoxPriceMin, prices?.BoxPriceMax, minBoxQty, maxBoxQty]
                | priceRange
            }}
          </span>
        </div>
        <div>
          <cs-rating-stars-readonly
            *ngIf="rating?.RatingCount"
            [rating]="rating?.AverageRating"
            [count]="rating?.RatingCount"
            [size]="16"
            [showNumbers]="true"
          />
          <cs-rating-stars-readonly
            *ngIf="displayOverallRating && overallRating?.AverageRating"
            [rating]="overallRating?.AverageRating"
            [count]="overallRating?.RatingCount"
            [size]="14"
            [showNumbers]="true"
            fillColor="var(--ion-color-step-300)"
            borderColor="var(--ion-color-step-600)"
            suffix="All"
          />
        </div>
      </div>
      <ion-button
        *ngIf="
          addToListsClicked.observed ||
          addToHumidorsClicked.observed ||
          deleteClicked.observed
        "
        fill="clear"
        (click)="presentActions($event)"
      >
        <ion-icon
          slot="icon-only"
          color="medium"
          name="ellipsis-vertical"
        ></ion-icon>
      </ion-button>
    </div>

    <div *ngIf="inLists?.length" class="px-2">
      <span class="mr-1 text-sm font-semibold">Location:</span>
      <span
        class="inline-flex items-center px-2 py-1 mb-1 mr-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-md dark:bg-gray-700 dark:text-gray-300"
        *ngFor="let list of inLists"
      >
        {{ list.ListTitle }}
      </span>
    </div>

    <!-- toolbar -->
    <div
      *ngIf="hasButtons"
      class="px-2 my-2 flex gap-2 mb-[2px] button-toolbar"
    >
      <ion-button
        *ngIf="myRating && upsertRating.observed"
        fill="outline"
        (click)="$event.stopPropagation(); upsertRating.emit()"
        [disabled]="isLoading"
        size="small"
        mode="ios"
        color="medium"
      >
        <cs-rating-stars-readonly
          *ngIf="myRating?.Rating"
          fillColor="#FDC70C"
          borderColor="#FDC70C"
          [rating]="myRating.Rating"
          [size]="16"
          [starsCount]="1"
        />

        {{ myRating.Rating | number : '1.1-2' }}
      </ion-button>
      <ion-button
        *ngIf="customPrice"
        fill="outline"
        (click)="$event.stopPropagation(); upsertPrice.emit()"
        [disabled]="isLoading"
        size="small"
        mode="ios"
        color="medium"
      >
        {{ customPrice | currency : 'USD' : 'symbol' : '1.0-2' }}
      </ion-button>
      <ion-button
        *ngIf="note"
        fill="outline"
        (click)="$event.stopPropagation(); upsertNote.emit()"
        [disabled]="isLoading"
        size="small"
        mode="ios"
        color="medium"
      >
        <ion-icon slot="icon-only" src="assets/images/pencil.svg"></ion-icon>
      </ion-button>
      <ion-button
        *ngIf="cigar.ProductId && smokingTime && upsertSmokingTime.observed"
        fill="outline"
        (click)="$event.stopPropagation(); upsertSmokingTime.emit()"
        [disabled]="isLoading"
        size="small"
        mode="ios"
        color="medium"
      >
        <ion-icon src="assets/images/timer.svg"></ion-icon>{{ smokingTime }}'
      </ion-button>
      <form
        class="flex flex-row items-center justify-end flex-1 gap-2"
        [formGroup]="qtyFormGroup"
        *ngIf="quantityChange.observed"
      >
        <ion-button
          fill="outline"
          (click)="historyClick.emit()"
          [disabled]="isLoading"
          size="small"
          mode="ios"
          color="medium"
        >
          <ion-icon slot="icon-only" src="assets/images/history.svg"></ion-icon>
        </ion-button>
        <!-- <ion-chip
          [outline]="true"
          (click)="quantityUpdate($event)"
          [disabled]="isLoading"
          >-</ion-chip
        > -->
        <ion-button
          fill="outline"
          (click)="quantityUpdate($event)"
          [disabled]="isLoading"
          size="small"
          mode="ios"
          color="medium"
        >
          <ion-icon slot="icon-only" name="remove-outline"></ion-icon>
        </ion-button>
        <ion-button
          fill="outline"
          (click)="quantityUpdate($event, true)"
          [disabled]="isLoading"
          size="small"
          mode="ios"
          color="medium"
        >
          <ion-icon slot="icon-only" name="add-outline"></ion-icon>
        </ion-button>
        <!-- <ion-chip
          [outline]="true"
          (click)="quantityUpdate($event, true)"
          [disabled]="isLoading"
          >+</ion-chip
        > -->
        <!-- <span contenteditable formControlName="quantity"> </span> -->
        <ion-item class="quantity" mode="md" lines="none">
          <ion-input
            [disabled]="isLoading"
            type="number"
            formControlName="quantity"
            fill="outline"
            class="h-[25px] !min-h-0"
            [ngClass]="{
              'w-[40px]': qtyFormGroup.get('quantity').value <= 9,
              'w-[45px]':
                qtyFormGroup.get('quantity').value > 9 &&
                qtyFormGroup.get('quantity').value <= 99,
              'w-[55px]': qtyFormGroup.get('quantity').value > 99
            }"
            min="0"
            #quantityInput
            (keyup.enter)="blurQuantity(quantityInput)"
          ></ion-input>
        </ion-item>
      </form>
    </div>
  </div>
</ng-container>

<ng-content></ng-content>
