import { BaseModel } from './base.model';

export class ProductPricesModel extends BaseModel {
  static mappings = {
    SinglePrice: 'SinglePriceMin',
    PartnerSinglePriceMin: 'SinglePriceMin',
    PartnerSinglePriceMax: 'SinglePriceMax',
    PartnerBoxPriceMin: 'BoxPriceMin',
    PartnerBoxPriceMax: 'BoxPriceMax',
  };
  SinglePrice?: number;
  SinglePriceMin: number;
  SinglePriceMax: number;
  BoxPriceMin: number;
  BoxPriceMax: number;
  DisplayPartnerPrices: boolean | null;
}
