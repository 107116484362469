import { addOutline, removeOutline, ellipsisVertical } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ActionSheetButton,
  ActionSheetController,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
} from '@ionic/angular/standalone';
import {
  ICigar,
  ICigarLite,
  ICigarLogListEnum,
  ICigarLogListIds,
  ICigarLogListType,
  ICigarRating,
  IMyRating,
  IProductSearch,
} from '@models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RxLet } from '@rx-angular/template/let';
import { ProductPricesModel } from '@shared/models/product-prices.model';
import { CigarSizePipe } from '@shared/pipes/cigar-size.pipe';
import { PriceRangeStandalonePipe } from '@shared/pipes/price-range2.pipe';
import { debounce, filter, interval, tap } from 'rxjs';

import { CigarBrandLogoComponent } from '../cigar-brand-logo/cigar-brand-logo.component';
import { RatingStarsReadOnlyComponent } from '../rating-stars/rating-stars-readonly.component';

@UntilDestroy()
@Component({
  selector: 'cigar-info',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RatingStarsReadOnlyComponent,
    CigarBrandLogoComponent,
    RxLet,
    CigarSizePipe,
    PriceRangeStandalonePipe,
    IonItem,
    IonLabel,
    IonButton,
    IonIcon,
    IonInput,
  ],
  templateUrl: 'cigar-info.html',
  styles: [
    `
      :host {
        display: block;
        position: relative;
        blockquote {
          border-left: 5px solid var(--ion-color-step-150);
          padding: 0 20px;
          margin: 5px 0;
          font-size: 1.2em;
          p {
            color: var(--ion-color-step-600, #666666);
          }
        }
        .subtitle {
          font-size: 0.75rem !important;
          line-height: 1rem !important;
          color: var(--ion-color-step-650);
        }
      }

      .button-toolbar {
        ion-button {
          --padding-end: 5px;
          --padding-start: 5px;
          --border-width: 1px;
          font-size: 12px;
          margin: 0;
          ion-icon {
            font-size: 15px;
          }
        }
        ion-item:not(.quantity) {
          --padding-start: 10px;
          --inner-padding-end: 5px;
          --padding-bottom: 5px;
          --padding-top: 5px;
          --padding-start: 5px;
        }
        ion-item.quantity {
          --min-height: 20px;
          --padding-bottom: 0;
          --padding-top: 0;
          --padding-start: 0;
          --inner-padding-end: 0;
          ion-input {
            --padding-end: 0;
            --padding-start: 10px;
            --border-radius: 6px;
            --border-color: var(--ion-color-medium);
            font-size: 12px;
            height: 26px;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CigarInfoComponent implements OnInit, OnChanges {
  @Input() displayLogo = true;
  @Input() isLoading = false;
  @Input() rating: ICigarRating;
  @Input() myRating: IMyRating;
  @Input() comment: string;
  @Input() truncateTitle = false;
  @Input() overallRating: ICigarRating;
  @Input() displayOverallRating = false;
  @Input() cigar: ICigarLite | ICigar;
  @Input() currentList?: ICigarLogListType;
  @Input() date: string;
  @Input() productPost: boolean;
  @Input() customPrice: number;
  @Input() actions = false;
  @Input() name = '';
  @Input() inLists: IProductSearch['InLists'] = [];
  @Input() length = '';
  @Input() ringGauge = '';
  @Input() note = '';
  @Input() prices: ProductPricesModel;
  @Input() minBoxQty: number;
  @Input() maxBoxQty: number;
  @Input() smokingTime: number;
  @Input() quantity: number;
  @Output() cigarClicked = new EventEmitter<void>();
  @Output() addToListsClicked = new EventEmitter<ICigarLogListIds>();
  @Output() addToHumidorsClicked = new EventEmitter<void>();
  @Output() deleteClicked = new EventEmitter<void>();
  @Output() quantityChange = new EventEmitter<number>();
  @Output() upsertSmokingTime = new EventEmitter<void>();
  @Output() upsertRating = new EventEmitter<void>();
  @Output() upsertNote = new EventEmitter<void>();
  @Output() upsertPrice = new EventEmitter<void>();
  @Output() historyClick = new EventEmitter<void>();
  qtyFormGroup = this.fb.group(
    {
      quantity: [0, [Validators.min(0)]],
    },
    {
      updateOn: 'blur',
    }
  );

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private fb: FormBuilder
  ) {
    addIcons({ ellipsisVertical, removeOutline, addOutline });
    this.qtyFormGroup.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.qtyFormGroup.valid),
        debounce(() => interval(800)),
        tap(({ quantity }) => {
          this.quantityChange.emit(quantity);
        })
      )
      .subscribe();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quantity !== undefined) {
      this.qtyFormGroup.get('quantity').setValue(this.quantity, {
        emitEvent: false,
      });
    }
  }

  clickItem(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.cigarClicked.emit();
  }

  quantityUpdate(e: Event, up: boolean) {
    e.stopPropagation();
    const ctrl = this.qtyFormGroup.get('quantity');
    const newValue = ctrl.value + (up ? +1 : -1);
    if (newValue >= 0) {
      ctrl.setValue(newValue);
    }
  }

  async blurQuantity(ionInput: IonInput) {
    const input = await ionInput.getInputElement();
    input.blur();
  }

  async presentActions(e: Event) {
    e.stopPropagation();
    let buttons: ActionSheetButton[] = [];
    if (this.addToListsClicked.observed) {
      const lists = [
        { text: 'Add to Journal', id: ICigarLogListEnum.JOURNAL_LIST },
        { text: 'Add to Favorites', id: ICigarLogListEnum.FAVORITES_LIST },
        { text: 'Add to Wish List', id: ICigarLogListEnum.WISH_LIST },
      ]
        .filter((b) => b.id !== this.currentList)
        .map((b) => ({
          text: b.text,
          data: {
            action: 'list',
            id: b.id,
          },
        }));
      buttons = [...buttons, ...lists];
    }
    if (this.addToHumidorsClicked.observed) {
      buttons.push({
        text: 'Add to Humidors',
        data: {
          action: 'humidors',
        },
      });
    }
    if (this.upsertRating.observed) {
      buttons.push({
        text: this.cigar.MyRating ? 'Edit Review' : 'Add a Review',
        data: {
          action: 'rating',
        },
      });
    }
    if (this.upsertNote.observed) {
      buttons.push({
        text: this.cigar.MyNote ? 'Edit Note' : 'Add a Note',
        data: {
          action: 'note',
        },
      });
    }
    if (this.upsertPrice.observed) {
      buttons.push({
        text: this.customPrice ? 'Edit Price' : 'Add a Price',
        data: {
          action: 'price',
        },
      });
    }
    // we can't add a smoking time to a line
    if (this.cigar.ProductId && this.upsertSmokingTime.observed) {
      buttons.push({
        text:
          this.cigar['MyCigarFeatures']?.SmokingTime || this.cigar.SmokingTime
            ? 'Edit Smoking Time'
            : 'Add Smoking Time',
        data: {
          action: 'smokingTime',
        },
      });
    }
    if (this.deleteClicked.observed) {
      buttons.push({
        text: 'Remove',
        role: 'destructive',
        data: {
          action: 'delete',
        },
      });
    }
    buttons.push({
      text: 'Cancel',
      role: 'cancel',
      data: {
        action: 'cancel',
      },
    });
    const actionSheet = await this.actionSheetCtrl.create({
      buttons,
    });

    await actionSheet.present();

    const result = await actionSheet.onDidDismiss();
    if (result.data) {
      switch (result.data.action) {
        case 'list':
          this.addToListsClicked.emit(result.data.id);
          break;
        case 'humidors':
          this.addToHumidorsClicked.emit();
          break;
        case 'delete':
          this.deleteClicked.emit();
          break;
        case 'rating':
          this.upsertRating.emit();
          break;
        case 'note':
          this.upsertNote.emit();
          break;
        case 'smokingTime':
          this.upsertSmokingTime.emit();
          break;
        case 'price':
          this.upsertPrice.emit();
          break;
        default:
          break;
      }
    }
  }
}
