import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { NumeralPipe } from '@shared/pipes/numeral.pipe';

@Component({
  selector: 'cs-rating-stars-readonly',
  standalone: true,
  imports: [CommonModule, NumeralPipe],
  template: `
    <div
      class="stars"
      [ngClass]="'stars-count-' + starsCount"
      [ngStyle]="{
        '--rating': rating,
        '--star-size': size,
        '--star-color': '#eee',
        '--star-background': fillColor
      }"
    ></div>
    <div class="ml-1 truncate text" *ngIf="showNumbers || suffix">
      <span *ngIf="showNumbers && rating">{{ rating | number : '1.1-2' }}</span>
      <span *ngIf="showNumbers && count">
        ({{ count | numeral }}
        <span [ngPlural]="count">
          <ng-template ngPluralCase="=0"></ng-template>
          <ng-template ngPluralCase="=1">review</ng-template>
          <ng-template ngPluralCase="other">reviews</ng-template> </span
        >)
      </span>
      <span *ngIf="suffix">{{ suffix }}</span>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        .text {
          color: var(--ion-color-step-650);
          font-size: 0.75rem;
          line-height: normal;
        }
        .stars {
          height: inherit;
          --percent: calc(var(--rating) / 5 * 100%);
          display: inline-block;
          font-size: calc(var(--star-size) * 1px);
          font-family: Times; // make sure ★ appears correctly
          line-height: 1;

          &::before {
            content: '★★★★★';
            letter-spacing: 2px;
            background: linear-gradient(
              90deg,
              var(--star-background) var(--percent),
              var(--ion-color-step-300) var(--percent)
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.stars-count-5::before {
            content: '★★★★★';
          }
          &.stars-count-4::before {
            content: '★★★★';
          }
          &.stars-count-3::before {
            content: '★★★';
          }
          &.stars-count-2::before {
            content: '★★';
          }
          &.stars-count-1::before {
            content: '★';
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingStarsReadOnlyComponent {
  @Input() starsCount = 5;
  @Input() rating: number;
  @Input() count: number;
  @Input() showNumbers = false;
  @Input() size: number;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() fillColor = '#DD423E';
}
