import { RxLet } from '@rx-angular/template/let';
import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';

@Component({
  selector: 'cigar-brand-logo',
  standalone: true,
  imports: [CommonModule, RxLet, CreateSourceStandalonePipe],
  template: `
    <div class="logo-wrapper">
      <ng-container *rxLet="cigar.ProductId === null; let isLine">
        <ng-container
          *ngIf="
            cigar.CigarBandImgUrl ||
              cigar.ImageUrlSmall ||
              cigar.ImageUrl as image;
            else noImage
          "
        >
          <img
            *ngFor="let number of isLine ? [0, 1, 2] : [0]"
            [ngClass]="{ line: isLine, single: !isLine }"
            class="bg-white  dark:bg-gray-800 border-gray-300 dark:border-gray-300 border-solid border"
            loading="lazy"
            [src]="
              image | createSource : 'assets/images/cigars_placeholder.svg'
            "
            src-fallback="assets/images/cigars_placeholder.svg"
          />
        </ng-container>
        <ng-template #noImage>
          <img
            *ngFor="let number of isLine ? [0, 1, 2] : [0]"
            [ngClass]="{ line: isLine, single: !isLine }"
            class="bg-white  dark:bg-gray-800 border-gray-300 dark:border-gray-300 border-solid border"
            loading="lazy"
            src="assets/images/humidor-details-cigar.svg"
          />
        </ng-template>
      </ng-container>
    </div>
  `,
  styleUrls: ['cigar-brand-logo.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CigarBrandLogoComponent {
  @Input() cigar;

  constructor() {}
}
