import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cigarSize',
  standalone: true,
})
export class CigarSizePipe implements PipeTransform {
  transform(length: string | null, ringGauge: number | null): any {
    if (length && ringGauge) {
      return `${length} x ${ringGauge}`;
    }
    return '';
  }
}
